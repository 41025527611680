<template>
  <v-card class="datail-card rounded-lg" elevation="0">
    <div class="empty">
      <!--<div>-->
      <!--<v-icon large color="deep-orange lighten-4">-->
      <!--mdi-alert-circle-->
      <!--</v-icon>-->
      <!--</div>-->
      <!--<div>词库查询不到该单词，请联系管理员补充</div>-->
      <van-empty class="v-empty" :image="offLine ? 'network' : defaultImg">
        <div class="desc" v-show="!offLine">
          似乎还没有新的内容<br />(*/ω＼*)
        </div>
        <div class="desc" v-show="offLine">网络出错啦！</div>
      </van-empty>
      <div class="empty-back">
        <v-btn
          class="ma-2"
          outlined
          color="indigo lighten-3"
          @click="doRefresh"
          @touchstart="zzzShort"
          @touchend="zzzMid"
        >
          刷新重试
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "errorPage",
  mixins: [buttonVibrate],
  props: {
    offLine: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaultImg: require("@/assets/uiimg/empty-image-default.png"),
    searchImg: require("@/assets/uiimg/empty-image-search.png"),
  }),
  methods: {
    goBack() {
      this.$router.back();
    },
    doRefresh() {
      this.$emit("doRefresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.datail-card {
  background-color: #f1f3f8;
  margin: 1.7rem 0.7rem;
  padding: 0.3rem 0.9rem;
  font-size: 0.85rem;
  letter-spacing: 0.05rem;
  height: 70vh;
  position: relative;

  .empty {
    min-height: 8rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.8rem;
    color: #909090;
    text-align: center;
    //> div {
    //  margin: 0.6rem;
    //}
    .v-empty {
      margin-top: -5rem;

      .desc {
        margin: 0.6rem;
      }
    }

    .empty-back {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0;
    }
  }
}
</style>
